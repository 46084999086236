<template>
  <div class="contents userMgt" v-loading="loading">
    <div class="title flexB">
      <h2>회원관리</h2>
    </div>
    <div class="box one filter">
      <div class="flex">
        <p class="bold">검색조건</p>
        <select v-model="inputMode">
          <option value="" disabled>선택</option>
          <option value="username">이름</option>
          <option value="email">이메일</option>
          <option value="mobileNo">연락처</option>
          <option value="address">주소</option>
        </select>
        <input type="text" v-model="searchWord" @keyup.enter="search" />
      </div>
      <!-- <div class="flex">
        <p class="bold">회원등급</p>
        <label> <input type="radio" value="" checked />전체 </label>
        <label> <input type="radio" value="" checked />실버 </label>
        <label> <input type="radio" value="" checked />골드 </label>
        <label> <input type="radio" value="" checked />다이아몬드 </label>
      </div> -->
      <div class="flex">
        <p class="bold">성별</p>
        <label>
          <input type="radio" v-model="gender" value="" checked />전체
        </label>
        <label> <input type="radio" v-model="gender" value="M" />남 </label>
        <label> <input type="radio" v-model="gender" value="F" />여 </label>
      </div>
      <div class="flex">
        <p class="bold">결혼</p>
        <label>
          <input type="radio" v-model="isMarried" value="" checked />전체
        </label>
        <label>
          <input type="radio" v-model="isMarried" value="true" checked />기혼
        </label>
        <label>
          <input type="radio" v-model="isMarried" value="false" checked />미혼
        </label>
      </div>
      <div class="flex">
        <p class="bold">직업</p>
        <label>
          <input type="radio" v-model="job" value="" checked />전체
        </label>
        <label>
          <input type="radio" v-model="job" value="EMPLOYEE" checked />직장인
        </label>
        <label>
          <input
            type="radio"
            v-model="job"
            value="SELFEMPLOYED"
            checked
          />자영업
        </label>
        <label>
          <input type="radio" v-model="job" value="UNEMPLOYED" checked />무직
        </label>
      </div>

      <div class="buttonWrap">
        <button class="basic" @click="search">검색</button>
        <button class="line" @click="reset">초기화</button>
        <button class="line" @click="download_csv">csv 다운로드</button>
      </div>
    </div>
    <div class="box one">
      <h2 class="bold">회원리스트</h2>
      <table>
        <colgroup>
          <col style="width: 8%" />
          <col style="width: 5%" />
          <col style="width: 10%" />
          <col style="width: 20%" />
          <col style="width: 4%" />
          <col style="width: 4%" />
          <col style="width: 4%" />
          <col style="width: 5%" />
          <col style="width: 12%" />
          <col style="width: 8%" />
          <col style="width: 10%" />
          <col style="width: 10%" />
        </colgroup>
        <tr>
          <th>번호</th>
          <th>권한</th>
          <th>이름</th>
          <th>이메일</th>
          <th>나이</th>
          <th>성별</th>
          <th>결혼</th>
          <th>직업</th>
          <th>연락처</th>
          <th>회원등급</th>
          <th>가입일</th>
          <th>주소</th>
        </tr>
        <tr v-for="(data, i) in userList" :key="i">
          <td>
            <router-link :to="`/admin/userDetail?id=${data._id}`">{{
              total + 1 - (i + 1 + (currentPage - 1) * 10)
            }}</router-link>
          </td>
          <td>{{ data.isAdmin == true ? "관리자" : "일반" }}</td>
          <td>
            <router-link :to="`/admin/userDetail?id=${data._id}`">{{
              data.username
            }}</router-link>
          </td>
          <td>{{ data.email }}</td>
          <td>{{ year - new Date(data.birth).getFullYear() + 1 }}</td>
          <td>
            {{ data.gender == "M" ? "남성" : data.gender == "F" ? "여성" : "" }}
          </td>
          <td>
            {{
              data.isMarried == true
                ? "기혼"
                : data.isMarried == false
                ? "미혼"
                : ""
            }}
          </td>
          <td>
            {{
              data.job == "EMPLOYEE"
                ? "직장인"
                : data.job == "SELFEMPLOYED"
                ? "자영업"
                : data.job == "UNEMPLOYED"
                ? "무직"
                : ""
            }}
          </td>
          <td>{{ getMask(data.mobileNo) }}</td>
          <td>
            {{
              data.level == "SILVER"
                ? "실버"
                : data.level == "GOLD"
                ? "골드"
                : data.level == "DIAMOND"
                ? "다이아몬드"
                : ""
            }}
          </td>
          <td>
            {{ moment(data.createdAt).format("YYYY.MM.DD") }}
          </td>
          <td>{{ data.address }}</td>
        </tr>
      </table>
      <NoData v-if="userList.length == 0" />
      <div class="pagination" v-if="userList.length > 0">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { fetchUserList } from "@/api/admin";
import { format } from "@/mixins/format";
import NoData from "@/components/contents/Nodata";
import XLSX from "xlsx";

export default {
  mixins: [format],
  name: "UserManage",
  components: { NoData },
  data() {
    return {
      moment: moment,
      inputMode: "",
      searchWord: "",
      userList: [],
      total: 0,
      currentPage: 1,
      year: "",
      gender: "",
      job: "",
      isMarried: "",
      loading: false,
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", 1);
  },
  mounted() {
    this.loading = true;
    this.setYear();
    this.getUserList();
  },
  methods: {
    setYear() {
      let today = new Date();
      this.year = today.getFullYear();
    },
    reset() {
      this.inputMode = "";
      this.searchWord = "";
      this.userList = [];
      this.total = 0;
      this.gender = "";
      this.job = "";
      this.isMarried = "";
      this.currentPage = 1;
      this.getUserList();
    },
    download_csv() {
      let userData = this.userList.map((item, i) => {
        return {
          번호: i + 1,
          이름: item.username,
          이메일: item.email,
          연락처: item.mobileNo,
          생년월일: item.birth,
          성별: item.gender == "F" ? "여" : "남",
          주소: item.address,
          직업:
            item.job == "EMPLOYEE"
              ? "직장인"
              : item.job == "SELFEMPLOYED"
              ? "자영업"
              : "무직",
          결혼여부: item.isMarried == true ? "기혼" : "미혼",
          가입일: moment(item.createdAt).format("YYYY.MM.DD HH:mm"),
          포인트: item.point,
        };
      });
      var excelData = XLSX.utils.json_to_sheet(userData); // table id를 넣어주면된다
      var workBook = XLSX.utils.book_new(); // 새 시트 생성
      XLSX.utils.book_append_sheet(workBook, excelData, "회원 리스트"); // 시트 명명, 데이터 지정
      XLSX.writeFile(workBook, "userList.xlsx"); // 엑셀파일 만듬
    },
    search() {
      if (this.searchWord && this.inputMode == "") {
        return alert("검색 조건을 선택을 주세요.");
      } else if (this.inputMode && this.searchWord == "") {
        return alert("검색어를 입력해 주세요.");
      }
      this.currentPage = 1;
      this.getUserList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getUserList();
    },
    getUserList() {
      let params = {
        page: this.currentPage,
        inputMode: this.inputMode,
        searchWord: this.searchWord,
        gender: this.gender,
        job: this.job,
        isMarried: this.isMarried,
      };
      fetchUserList(params).then((res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            this.userList = res.data.users;
            this.total = res.data.total;
            this.loading = false;
          } else if (res.data.status == 409) {
            this.$router.push("/");
            return alert(res.data.message);
          }
        } else {
          alert(res.data.message);
        }
      });
    },
  },
};
</script>
